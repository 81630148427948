@import './settings';
@import './mixins';

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  html {
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  html,
  html body {
    height: initial !important;
  }
}

.pushable > .pusher.main-content-container {
  overflow-x: scroll;
  @include media-breakpoint-up(lg) {
    margin-right: 260px;
  }
}

.pushable > .pusher {
  &.add-payments {
    background-color: $tulipTree;
  }
}

.content-container {
  margin: 10px;
  @include media-breakpoint-up(lg) {
    margin: 25px;
  }

  &.edit-table-container {
    overflow-x: scroll;

    @include media-breakpoint-up(lg) {
      max-width: fit-content;
    }
  }
}

.small-field {
  width: 50px;
}

.edit-buttons {
  min-width: 100px;
}

.icon-button {
  cursor: pointer;
}

.date-menu {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}

.ui {
  &.segment {
    &.no-padding {
      padding: 0;
      margin: 0;
      border: none;
    }
  }

  &.dimmer {
    background-color: rgba(0, 0, 0, 0.75) !important;
  }
}

.date-picker {
  input {
    width: 115px;
  }
}

.date-time-picker {
  input {
    width: 200px;
  }
}

.icon-button {
  cursor: pointer;

  &.delete {
    color: red;
  }
}

.panel {
  margin-bottom: 25px;
}

.error {
  color: red;
}

.flag {
  color: red;
}

.warning {
  color: orange;
}

.permission-section {
  background-color: $galleryGray;
}

.total {
  position: absolute;
  left: 200px;
}

.ui.accordion:not(.styled) {
  .title {
    padding-left: 15px;
    p {
      margin-bottom: 10px;
    }
  }

  .title ~ .content:not(.ui).accordion-content-1 {
    padding-top: 0;
    padding-left: $marginSm;

    h5 {
      margin-top: 10px;
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  &.accordion-level-1 {
    .title {
      padding-bottom: 0;
    }
  }
  .accordion {
    &.accordion-level-2 {
      margin-top: 0;
      margin-bottom: $marginMd;

      table {
        margin-bottom: 25px;
      }
    }
  }
}

.voided-row {
  text-decoration: line-through;
}

.voided {
  color: red;
}

.payments {
  td {
    vertical-align: top;
  }
}

.ui.vertical.menu.main-menu {
  @include media-breakpoint-down(sm) {
    .menu .item {
      padding-top: 0.7em;
      padding-bottom: 0.7em;
      font-size: 16px;
    }
  }
}

.mouse-hand {
  cursor: pointer;
}

.additional-properties {
  margin-top: 20px;
}

.ui.button {
  &.save-property {
  }

  &.additional-fields-button {
    margin-top: 10px;
  }

  &.task-action-button {
    margin-left: 10px;
  }
}

// Semantic overrides
.ui {
  &.table {
    &.table-list {
      margin-bottom: 20px;
    }
  }
  &.grid {
    &.addtional-property-type {
      margin-bottom: 10px;
    }
  }
  &.form {
    &.task-form {
      margin-bottom: 10px;
    }
  }
}

.selected {
  background-color: yellow;
}

.task-name {
  margin-top: 5px;
}

.completed {
  .task-name {
    text-decoration: line-through;
  }
}

.additional-field {
  background-color: #efefef;
}

.mobile-table-header {
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
}

.mobile-checkbox {
  margin-right: 5px;
  margin-bottom: -3px;
}

.additional-field-container {
  margin-bottom: 10px;
}

.task-status {
  margin-bottom: 25px;
}

.task-list-mode {
  margin-bottom: 10px;
  > label {
    font-weight: bold;
  }
  label {
    padding-right: 10px;
  }
}

.task-section {
  background-color: $pelorous;
  font-weight: bold;
}

.highlighted {
  background-color: $galleryGray;
}

.custom-tooltip {
  background-color: $white;
  border-color: $steelGray;
  padding: 10px;
}

.app-info {
  background-color: $galleryGray;
}

.ui.sidebar.menu .item {
  &.app-info {
    background-color: $altoGray;
  }
}

.task-list-template-options {
  max-height: 400px;
  overflow-y: scroll;
}

.task-cloned {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: -3px;
    right: 50px;
    width: 90px;
  }
}

h5.ui.header.manager {
  margin-bottom: 0;
}

.managers-container {
  padding-bottom: $marginSm;
}

.ui.dropdown .menu > .header.dropdown-header {
  background-color: $altoGrayDarker;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: $marginSm;
  padding-bottom: $marginSm;
}

.markdown-page {
  max-width: 960px;

  li:before {
    margin-left: -15px;
  }
}