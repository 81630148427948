@import "../../../scss/site";

.date-start {
  margin-right: $marginSm;
}

.rv-discrete-color-legend-item__color {
  height: 10px;
  padding-top: 3px;
  margin-right: 5px;
}

.rv-discrete-color-legend-item__title {
  display: block;
  margin-left: 25px;
  margin-top: -17px;
}

.pie-chart {
  width: auto !important;
}

h2.ui.header {
  margin-top: 5px;

  &.for-table {
    margin-top: 10px;
  }
}

table.ui.table {
  &.item-group:last-child {
    margin-bottom: 20px;
  }
}

.legend-column {
  padding: 0 !important;
  margin-left: -15px;
}

.qty {
  width: 100px;
}

h3.ui.header {
  margin-bottom: 5px;
}

h4.ui.header {
  .icon {
    display: inline-block;
  }
}
