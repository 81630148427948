.ui.segment.date-selector {

}

.date-adjust-button {
  cursor: pointer;
  line-height: 1.33 !important;
}

.day-display {
  margin-left: 40px;
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  text-transform: uppercase;
}

i.icon.fast-backward-button {
  margin-left: 50px;
  margin-right: 20px;
  cursor: pointer;
}

i.icon.fast-forward-button {
  cursor: pointer;
}