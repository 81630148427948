@import '../../node_modules/bootstrap/scss/bootstrap-grid';

$marginSm: 10px;
$marginMd: 20px;
$marginLg: 40px;
$redRibbon: #e40c2b;
$steelGray: #1d1d2c;
$merino: #f7f4e9;
$pelorous: #3cbcc3;
$tulipTree: #eba63f;
$peachOrange: #FED394;
$goblin: #438945;
$pastelGreen: #90EE90;
$galleryGray: #ededed;
$altoGray: #e0e0e0;
$altoGrayDarker: #e0e0e0;
$alabaster: #f9f9f9;
