@import "../../scss/site";

.pushable.menu-sidebar {
  &.menu-sidebar {
    height: 100vh;
  }
}

.hamburger-menu {
  cursor: pointer;
}

.content-container {
  margin-top: 10px;
}

.ui.grid.celled.top-menu.top-menu {
  margin-top: 0;
  margin-bottom: 0;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.pusher {
  &.main-content-container {
    @include media-breakpoint-up(lg) {
      overflow-y: scroll !important;
      height: 100vh;
    }
  }
}