@page {
  size: 8.5in 11in;
  margin: 0.25in;
}

@media print {
  table {
    border: solid 1px #999;
    border-collapse: collapse;

    th {
      background-color: #ddd !important;
    }

    .task-section {
      background-color: #eee;
    }

    th,
    td {
      padding: 5px;
      font-size: 11px;
    }

    td {
      border-right: solid 1px #ccc;
      &:last-child {
        border-right: none;
      }
    }
  }
}
